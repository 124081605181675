/** @jsx jsx */
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Button, Container, jsx } from "theme-ui";
import { Link } from "gatsby";
import PrimaryLayout from "../../components/layout/primary/primary";
import AccountTopMenu from "../../components/top-menus/account/account-top-menu";

const UserActivityPage: React.FunctionComponent<any> = (props: any) => {
  return (
    <PrimaryLayout topMenuChild={AccountTopMenu}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container py="40px" sx={styles.container}>
        <div className="row">
          <div sx={styles.wrapper} className="col-lg-10">
            <h1 sx={styles.header}>Activity</h1>
            <hr className="bar-indent-center" sx={styles.headerline} />

            <Table responsive className="activityTable" id="activityTable">
              <thead role="rowgroup" id="activityTable">
                <tr role="row" id="activityTable">
                  <th role="columnheader"></th>
                  <th role="columnheader">Last Log in </th>
                  <th role="columnheader">IP </th>
                  <th role="columnheader">Action</th>
                  <th role="columnheader">Date</th>
                </tr>
              </thead>
              <tbody role="rowgroup" id="activityTable">
                <tr>
                  <td role="cell"></td>
                  <td role="cell">a date</td>
                  <td role="cell">an ip</td>
                  <td role="cell">email</td>
                  <td role="cell">date</td>
                </tr>
              </tbody>
            </Table>

            <Link to="/account/user-management" sx={styles.link}>
              <Button sx={styles.buttonDanger}>
                RESET USERS ACCESS DETAILS
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </PrimaryLayout>
  );
};

const styles = {
  wrapper: {
    border: "1px solid #e4e4e4",
    boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
    transition:
      "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    borderRadius: "6px",
    background: "white",
    position: "relative",
    paddingLeft: "30px",
    padding: "20px",
    margin: "0 auto",
  },
  container: {
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
  },
  header: {
    textAlign: "center",
    marginTop: " 3.5rem",
  },
  headerline: {
    marginBottom: "3rem",
  },
  contentContainer: {
    paddingBottom: "40px",

    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
  },
  contentWrapper: {
    border: "1px solid #e4e4e4",
    boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
    transition:
      "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    borderRadius: "6px",
    padding: "10px",
    background: "#F5F5F5",

    "@media only screen and (max-width: 768px)": {
      padding: 20,
    },
  },
  link: {
    display: "contents",
    listStyle: "none",
  },
  buttonDanger: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    marginTop: "2rem",
    padding: "12.5px 25px",
    borderRadius: "10px",
    background: "#DC3545",
    width: "100%",
    textTransform: "uppercase",

    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#DC3545",
      borderColor: "#DC3545",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  },
};

export default UserActivityPage;
